import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.standard
    ? _c(
        VCard,
        {
          staticClass: "elevation-12",
          staticStyle: {
            background: "#FFFFFF",
            "min-width": "300px",
            "max-width": "300px",
            position: "absolute",
            display: "block",
            left: "50%",
            "margin-left": "-150px",
            top: "50%",
            "margin-top": "-250px",
            height: "350px",
          },
        },
        [
          _c(
            VCard,
            { attrs: { flat: "" } },
            [
              _c(
                VCol,
                { attrs: { align: "center" } },
                [
                  _c(
                    VCol,
                    {
                      staticStyle: { "margin-top": "30px" },
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "700",
                            "font-size": "20px",
                            color: "#424242",
                          },
                        },
                        [_vm._v("LOGIN")]
                      ),
                      _c("div", { staticClass: "pa-2" }),
                      _c(
                        VBtn,
                        {
                          staticStyle: {
                            width: "80%",
                            "font-size": "13px",
                            color: "#175FC7",
                            "justify-content": "center",
                          },
                          attrs: { block: "", color: "#ffffff" },
                          on: {
                            click: function ($event) {
                              return _vm.snsLogin("google")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "margin-bottom": "3px",
                              "margin-right": "15px",
                            },
                            attrs: {
                              width: "20px",
                              alt: "Google sign-in",
                              src: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png",
                            },
                          }),
                          _vm._v(
                            "\n                    Login in with Google\n                "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "pa-2 clearfix or-box" }, [
                        _c("hr", {
                          staticClass: "or-line",
                          staticStyle: { width: "45%", "margin-right": "1px" },
                        }),
                        _c("div", { attrs: { id: "or-text" } }, [_vm._v("or")]),
                        _c("hr", {
                          staticClass: "or-line",
                          staticStyle: { width: "45%", "margin-left": "1px" },
                        }),
                      ]),
                      _c(
                        VBtn,
                        {
                          staticStyle: {
                            width: "250px",
                            "font-size": "13px",
                            color: "black",
                            "background-color": "white",
                            "justify-content": "center",
                          },
                          attrs: { block: "", color: "#000000" },
                          on: {
                            click: function ($event) {
                              return _vm.snsLogin("github")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "margin-bottom": "-1px",
                              "margin-right": "5px",
                            },
                            attrs: {
                              id: "git-hover",
                              width: "30px",
                              alt: "Github sign-in",
                              src: "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
                            },
                          }),
                          _vm._v(
                            "\n                    Login in with Github\n                "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "small",
                            color: "#BDBDBD",
                            "margin-top": "15px",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("loginList.cookieCheck1"))
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("loginList.cookieCheck2")) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.guest
    ? _c(
        VCard,
        {
          staticClass: "elevation-12",
          staticStyle: {
            width: "700px",
            position: "absoluite",
            "margin-top": "50px",
            margin: "0 auto",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("게스트 로그인 정보"),
                ]),
              ]),
              _c(
                VCardText,
                [
                  _c(
                    VForm,
                    {
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        VRow,
                        [
                          _vm.inputConnectionkey
                            ? _c(VTextField, {
                                attrs: { label: "접속 키", required: "" },
                                model: {
                                  value: _vm.connectionKey,
                                  callback: function ($$v) {
                                    _vm.connectionKey = $$v
                                  },
                                  expression: "connectionKey",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(VTextField, {
                            attrs: { label: "이름", required: "" },
                            model: {
                              value: _vm.userInfo.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.userInfo, "name", $$v)
                              },
                              expression: "userInfo.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "이메일",
                              required: "",
                              rules: _vm.emailRules,
                            },
                            model: {
                              value: _vm.userInfo.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.userInfo, "email", $$v)
                              },
                              expression: "userInfo.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n                아바타\n                "),
                      _c(
                        VRow,
                        _vm._l(22, function (i) {
                          return _c(
                            VCol,
                            [
                              _vm.userImage == i
                                ? _c(
                                    VBadge,
                                    {
                                      attrs: {
                                        avatar: "",
                                        bordered: "",
                                        overlap: "",
                                        color: "success",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "badge",
                                            fn: function () {
                                              return [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      color: "#ffffff",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    mdi-check-bold\n                                "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        VAvatar,
                                        {
                                          staticClass: "profile",
                                          staticStyle: {
                                            "margin-top": "5px",
                                            "margin-right": "7px",
                                          },
                                          attrs: { color: "grey", size: "50" },
                                          on: {
                                            click: function ($event) {
                                              _vm.userImage = null
                                            },
                                          },
                                        },
                                        [
                                          _c(VImg, {
                                            attrs: { src: _vm.userIcon(i) },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    VAvatar,
                                    {
                                      staticClass: "profile",
                                      staticStyle: {
                                        "margin-top": "5px",
                                        "margin-right": "7px",
                                      },
                                      attrs: { color: "grey", size: "50" },
                                      on: {
                                        click: function ($event) {
                                          _vm.userImage = i
                                        },
                                      },
                                    },
                                    [
                                      _c(VImg, {
                                        attrs: { src: _vm.userIcon(i) },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c("div", { staticClass: "caption" }, [
                        _vm._v("아이콘 제작자 "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.flaticon.com/kr/authors/freepik",
                              title: "Freepik",
                            },
                          },
                          [_vm._v("Freepik")]
                        ),
                        _vm._v(" from "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.flaticon.com/kr/",
                              title: "Flaticon",
                            },
                          },
                          [_vm._v(" www.flaticon.com")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeLogin()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "blue darken-1",
                        text: "",
                        disabled: !_vm.valid,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.joinClassByKey()
                        },
                      },
                    },
                    [_vm._v("입장")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.onlyConnectionKey
    ? _c(
        VCard,
        {
          staticStyle: {
            background: "#FFFFFF",
            "min-width": "400px",
            position: "absolute",
            top: "150px",
            left: "50%",
            "margin-left": "-200px",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("접속키 입력"),
                ]),
              ]),
              _c(
                VCardText,
                [
                  _vm.inputConnectionkey
                    ? _c(VTextField, {
                        attrs: { label: "접속 키", required: "" },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.inputConnectionKey()
                          },
                        },
                        model: {
                          value: _vm.connectionKey,
                          callback: function ($$v) {
                            _vm.connectionKey = $$v
                          },
                          expression: "connectionKey",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticStyle: { "margin-left": "250px" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeLogin()
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.inputConnectionKey()
                        },
                      },
                    },
                    [_vm._v("입력")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }